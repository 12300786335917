<template>
    <div class="pb-2">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.additionalTraining =
            !$parent.isExpand.additionalTraining),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas fa-angle-right arrow"
            :class="{'down':$parent.isExpand.additionalTraining}"
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">Additional Qualifications and Training</h5>
      </a>
      <Transition>
          <div v-if="$parent.isExpand.additionalTraining">
            <p class="text-center text-muted h6">No Information Found</p>
          </div>
      </Transition>
    </div>
  </template>
  <script>
  export default {
    name: "AdditionalTraining",
  };
  </script>
  <style lang="scss" scoped>
  .arrow{
      transition: transform 0.2s linear;
  }
  .arrow.down {
    transform: rotate(90deg);
    transition: transform 0.2s linear;
  }
  </style>
  